import type { Schemas } from '#shopware';

export const useCategoryHelper = () => {
    const { apiClient } = useShopwareContext();

    const getCategories = async (categoryIds: string[], fields: string[] = []): Promise<Schemas['Category'][]> => {
        try {
            if (categoryIds.length === 0) return [];

            const response = await apiClient.invoke('readCategoryList post /category', {
                // @ts-ignore
                ids: categoryIds,
                fields,
                associations: {
                    media: {},
                    seoUrls: {},
                    children: {},
                },
            });

            return response?.elements ?? [];
        } catch (error) {
            Logger.captureException(error);
        }

        return [];
    };

    const getChildrenIds = async (parentCategory: string) => {
        if (!parentCategory) return [];

        const { loadNavigationElements } = useNavigation({ type: parentCategory });
        const navElements = await loadNavigationElements({ depth: 1 });

        return navElements.map((child) => child.id);
    };

    const _getAllIds = (navElements: Schemas['NavigationRouteResponse']): string[] => {
        return navElements.reduce((ids: string[], element) => {
            ids.push(element.id);
            if (element.children && element.children.length) {
                ids.push(..._getAllIds(element.children));
            }
            return ids;
        }, []);
    };

    const getAllChildrenIdsRecursive = async (parentCategory: string) => {
        if (!parentCategory) return [];

        const { loadNavigationElements } = useNavigation({ type: parentCategory });
        const navElements = await loadNavigationElements({ depth: 5 });

        return _getAllIds(navElements);
    };

    return {
        getCategories,
        getChildrenIds,
        getAllChildrenIdsRecursive,
    };
};
